import React, { useContext } from "react";
import axios from "axios";
import OktaUtil from "../util/OktaUtil";
import LocalStorageConstants from "../constants/LocalStorageConstants";
import { useLoading } from "../contexts/LoadingContext";
import AuthContext from "../contexts/AuthContext";
import PropTypes from "prop-types";

const HttpInterceptor = ({ children }) => {
  const SERVICES_URL = process.env.SERVICES_URL;
  const { setLoading } = useLoading();
  const authContext = useContext(AuthContext);

  let pendingRequests = 0;

  axios.interceptors.request.use(config => {
    pendingRequests++;
    setLoading(true);

    const { accessToken, idToken } = JSON.parse(localStorage.getItem(LocalStorageConstants.OKTA_TOKEN));
    console.log(accessToken);

    if (config.url.includes(SERVICES_URL)) {
      config.headers.Authorization = `Bearer ${accessToken.accessToken}`;
      config.headers.idToken = idToken.idToken;
    }

    return config;
  });

  axios.interceptors.response.use(
    function(response) {
      pendingRequests--;
      if (!response) return null;
      if (pendingRequests === 0) setLoading(false);

      if (response.data) {
        if (typeof response.data === "string" && response.data.includes('input type="hidden" name="SAMLRequest"')) {
          OktaUtil.redirectToOkta();
          return Promise.reject("REDIRECTING TO OKTA");
        }
      }
      return response;
    },
    function(error) {
      pendingRequests--;
      if (pendingRequests === 0) setLoading(false);
      console.log(error);
      if (error) {
        if (error.message) {
          return Promise.reject(error);
        }
        if (error) {
          if (error.response && error.response.data && error.response.data.errors) {
          }
          return Promise.reject(error);
        }
      }
    }
  );

  return <>{children}</>;
};

HttpInterceptor.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
};

export default HttpInterceptor;
