import React from "react";
import { CardContainer, CardHeader, CardBody } from "best-common-react";

const Home = () => {
  return (
    <CardContainer>
      <CardHeader>Welcome</CardHeader>
      <CardBody>Welcome to MLB User Management.</CardBody>
    </CardContainer>
  );
};

export default Home;
