import React, { useContext } from "react";
import { LoadingBlocker } from "best-common-react";
import { useLoading } from "../../contexts/LoadingContext";

const Loading = () => {
  const { loading } = useLoading();
  return loading && <LoadingBlocker />;
};

export default Loading;
