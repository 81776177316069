import axios from "axios";

const SERVICES_URL = process.env.SERVICES_URL;

export const getCurrentUser = () => axios.get(`${SERVICES_URL}/user/userInfo`);

export const searchUsersByEmail = email => axios.get(`${SERVICES_URL}/user/searchUser/${email}`);

export const resetPassword = (email, password) =>
  axios.put(`${SERVICES_URL}/user/resetPassword/${email}`, { password: password });
