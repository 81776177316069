import React, { useContext } from "react";
import { AlertHolder } from "best-common-react";
import AlertContext from "../../contexts/AlertContext";
import styled from "styled-components";

const AlertWrapper = styled.div`
  > div {
    > div {
      display: table;
      left: 0px;
      right: 0px;
      margin: 0 auto;
      width: fit-content;
    }
  }
`;

const GlobalAlerts = () => {
  const alertContext = useContext(AlertContext);

  return (
    <AlertWrapper id="global-alerts">
      <AlertHolder
        fullWidth={false}
        alerts={alertContext.state.alerts}
        onRemove={id => {
          if (id) {
            alertContext.dispatch({ type: "removeAlert", id: id });
          }
        }}
      />
    </AlertWrapper>
  );
};

export default GlobalAlerts;
