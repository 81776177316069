import React, { useContext } from "react";
import { Router, Route } from "react-router-dom";
import { ErrorCatcher } from "best-common-react";
import { createBrowserHistory } from "history";
import Authentication from "./authentication/Authentication";
import OktaLogin from "./login/OktaLogin";
import AppContainer from "./AppContainer";
import HttpInterceptor from "./api/HttpInterceptor";
import Providers from "./providers";
import Loading from "./components/loading/Loading";
import Navigation from "./components/navigation/Navigation";
import GlobalAlerts from "./components/alerts/GlobalAlerts";

const history = createBrowserHistory();
const ENV = process.env.ENV;

const App = () => {
  return (
    <ErrorCatcher active={ENV && ENV.toUpperCase() === "PROD"} appName="MLB User Management">
      <Router history={history}>
        <Authentication history={history}>
          <OktaLogin>
            <React.Suspense fallback={<h1>Loading...</h1>}>
              <Providers>
                <HttpInterceptor>
                  <Loading />
                  <Navigation />
                  <GlobalAlerts />
                  <AppContainer />
                </HttpInterceptor>
              </Providers>
            </React.Suspense>
          </OktaLogin>
        </Authentication>
      </Router>
    </ErrorCatcher>
  );
};

export default App;
