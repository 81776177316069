import React, { useState, useContext } from "react";
import styled, { ThemeContext } from "styled-components";
import { resetPassword } from "../../api/UserMgmtApi";
import { useAlert } from "../../hooks/useAlert";
import {
  Modal,
  ModalHeaderWithTitleAndClose,
  ModalBody,
  ModalFooter,
  LightButton,
  PrimaryButton,
  Icon
} from "best-common-react";

const ResetPasswordModal = ({ show = false, onClose, email }) => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const Theme = useContext(ThemeContext);
  const showAlert = useAlert();

  const SuccessTheme = Theme.success;
  const DangerTheme = Theme.danger;

  const resetForm = () => {
    setPassword("");
    setConfirmPassword("");
    onClose();
  };

  const onSave = () => {
    resetPassword(email, password)
      .then(({ data }) => {
        if (data === "SUCCESS") {
          resetForm();
          showAlert("The user's password was updated successfully.");
        } else {
          showAlert("There was a problem saving the password.  Please try again.", "danger");
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  return (
    <>
      <Modal show={show} onClose={onClose}>
        <ModalHeaderWithTitleAndClose title="Reset Password" close={resetForm} />
        <ModalBody>
          <div>
            <form>
              <div className="form-group row">
                <label htmlFor="email" className="col-sm-4 col-form-label">
                  Email
                </label>
                <div className="col-sm-8 d-flex align-items-center">{email}</div>
              </div>
              <div className="form-group row">
                <label htmlFor="password" className="col-sm-4 col-form-label">
                  New Password
                </label>
                <div className="col-sm-8">
                  <input
                    type="password"
                    className="form-control"
                    id="password"
                    placeholder="Password"
                    value={password}
                    onChange={event => setPassword(event.target.value)}
                  />
                </div>
              </div>
              <div className="form-group row">
                <label htmlFor="confirm-password" className="col-sm-4 col-form-label">
                  Confirm Password
                </label>
                <div className="col-sm-8">
                  <input
                    type="password"
                    className="form-control"
                    id="confirm-password"
                    placeholder="Confirm Password"
                    value={confirmPassword}
                    onChange={event => setConfirmPassword(event.target.value)}
                  />
                  <div className="mt-2">
                    <Icon
                      iconName="fa-check-circle"
                      className="mr-1"
                      styles={{
                        color: password === confirmPassword ? SuccessTheme.color : Theme["grey-two"],
                        hoverColor: password === confirmPassword ? DangerTheme.color : Theme["grey-two"],
                        size: "0.75rem"
                      }}
                    />
                    <small style={{ color: password === confirmPassword ? SuccessTheme.color : DangerTheme.color }}>
                      Passwords must match
                    </small>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </ModalBody>
        <ModalFooter>
          <div>
            <PrimaryButton disabled={password === "" || password !== confirmPassword} className="mr-2" onClick={onSave}>
              Save
            </PrimaryButton>
            <LightButton onClick={resetForm}>Cancel</LightButton>
          </div>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default ResetPasswordModal;
