import React, { useContext } from "react";
import { NavigationFromConfig } from "best-common-react";
import RouteConstants from "../../constants/RouteConstants";
import AuthContext from "../../contexts/AuthContext";

const Nav = () => {
  const authContext = useContext(AuthContext);
  const ENV = process.env.ENV;
  const config = {
    env: ENV,
    displayEnvIndicator: !authContext.state.isProd,
    loggedIn: authContext.state.loggedIn,
    username: authContext.state.userInfo.email || "",
    logoText: "MLB User Management",
    tabletSupport: true,
    usernameDropdownLinks: [
      {
        name: "Sign Out",
        to: RouteConstants.LOGIN
      }
    ],
    navigationLinks: [
      {
        name: "Home",
        to: RouteConstants.BASE
      },
      {
        name: "Reset Password",
        to: RouteConstants.RESET_PASSWORD
      }
    ]
  };

  return (
    <div>
      <NavigationFromConfig config={config} />
    </div>
  );
};

export default Nav;
