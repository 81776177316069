import React, { useReducer } from "react";
import UserListContext from "../contexts/UserListContext";
import PropTypes from "prop-types";

const initialState = {
  userCount: 0,
  users: []
};

const UserListContextProvider = ({ children }) => {
  const reducer = (state, action) => {
    switch (action.type) {
      case "setUserList":
        return { ...state, userCount: action.userCount, users: action.users };
      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  return <UserListContext.Provider value={{ state, dispatch }}>{children}</UserListContext.Provider>;
};

UserListContextProvider.propTypes = {
  children: PropTypes.object.isRequired
};

export default UserListContextProvider;
