import "core-js/stable";
import "regenerator-runtime/runtime";
import React from "react";
import ReactDom from "react-dom";
import App from "./App";

import { ThemeProvider } from "styled-components";
import userMgmtTheme from "./userMgmtTheme";

// Allow setting some values by URL params
const url = new URL(window.location.href);
const pkce = !!url.searchParams.get("pkce");

ReactDom.render(
  <ThemeProvider theme={userMgmtTheme}>
    <App pkce={pkce} redirectUri="http://localhost:7000/implicit/callback" />
  </ThemeProvider>,
  document.getElementById("usermgmt-app")
);
