import React from "react";
import AlertContextProvider from "./AlertContextProvider";
import UserListContextProvider from "./UserListContextProvider";
import { LoadingProvider } from "../contexts/LoadingContext";

export default ({ children }) => (
  <AlertContextProvider>
    <UserListContextProvider>
      <LoadingProvider>{children}</LoadingProvider>
    </UserListContextProvider>
  </AlertContextProvider>
);
