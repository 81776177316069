import React, { useReducer } from "react";
import AuthContext from "../contexts/AuthContext";
import LocalStorageConstants from "../constants/LocalStorageConstants";
import PropTypes from "prop-types";

const ENV = process.env.ENV;
const IS_PROD = ENV === "prod";

const initialState = {
  loggedIn: false,
  isAdmin: false,
  isProd: IS_PROD,
  userInfo: {},
  authToken: "",
  userInfoFailed: false
};

if (localStorage.getItem(LocalStorageConstants.OKTA_TOKEN)) {
  const { accessToken, idToken } = JSON.parse(localStorage.getItem(LocalStorageConstants.OKTA_TOKEN));
  if (accessToken && idToken) {
    initialState.authToken = accessToken.accessToken;
    initialState.loggedIn = true;
    initialState.userInfo = JSON.parse(localStorage.getItem("userInfo"));
  }
}

const Authentication = ({ children }) => {
  const reducer = (state, action) => {
    switch (action.type) {
      case "login":
        const { accessToken, idToken } = JSON.parse(localStorage.getItem(LocalStorageConstants.OKTA_TOKEN));
        return { ...state, authToken: accessToken.accessToken, loggedIn: false, userInfo: {} };
      case "logout":
        localStorage.clear();
        return { ...initialState };
      case "setUserInfo":
        const userInfo = action.userInfo;
        const isAdmin = userInfo.admin === "true";
        localStorage.setItem("userInfo", JSON.stringify(userInfo));
        return { ...state, userInfo: action.userInfo, isAdmin: isAdmin, loggedIn: true };
      case "setUserInfoFailed":
        return {
          ...state,
          userInfoFailed: action.userInfoFailed
        };
      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  return <AuthContext.Provider value={{ state, dispatch }}>{children}</AuthContext.Provider>;
};

Authentication.propTypes = {
  children: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

export default Authentication;
