import { useContext, useCallback } from "react";
import AlertContext from "../contexts/AlertContext";
import { AlertConstants } from "best-common-react";

/**
 * Hook to send alerts
 */
export const useAlert = () => {
  const alertContext = useContext(AlertContext);
  const { dispatch } = alertContext;

  return useCallback(
    (message, messageType = AlertConstants.TYPES.SUCCESS) => {
      dispatch({
        type: "addAlert",
        alert: {
          type: messageType,
          text: message
        }
      });
    },
    [dispatch]
  );
};
