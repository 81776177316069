/*
1. if there is no token in the browser storage it redirect you to okta and gets a new token
2. if there is a token in the browser when you open the page it refreshes it
3. also when the browser/page is open token is continuously refresh
4. based on where the request came from, on successful login in the callback funtion onLogin value by name state is sent which returns your original url for you to redirect to that page
5. client should provide properties in env files (leave it like that for now, no harm)
6. client will also need to send a callback funtion

Sample invocation
useOkta(onLogin); -- onLogin is the callback on successful login. 
*/

import React, { useEffect } from "react";
import OktaAuth from "@okta/okta-auth-js";
import queryString from "query-string";

const authClient = new OktaAuth({
  url: process.env.REACT_APP_OKTA_BASE_URL,
  clientId: process.env.REACT_APP_OKTA_CLIENT_ID,
  issuer: process.env.REACT_APP_OKTA_ISSUER,
  autoRenew: true,
  redirectUri: process.env.REACT_APP_OKTA_REDIRECT_URI
});

export const handleLogin = onLogin => {
  authClient.tokenManager.get("accessToken").then(tokens => {
    let windowHash = window.location.hash;
    if (tokens) {
      onLogin();
    } else if (windowHash) {
      authClient.token.parseFromUrl().then(token => {
        token.forEach(t => {
          if (t.idToken) {
            authClient.tokenManager.add("idToken", t);
          }
          if (t.accessToken) {
            authClient.tokenManager.add("accessToken", t);
          }
        });
        //parse url
        const param = queryString.parse(windowHash);
        const state = param.state;
        onLogin(state);
      });
    } else {
      authClient.token.getWithRedirect({
        responseType: ["token", "id_token"],
        scopes: ["openid", "profile", "email", "groups"],
        expireEarlySeconds: 120,
        nonce: "uSeRInfo",
        state: encodeURIComponent(window.location.pathname + window.location.search)
      });
    }
  });
  authClient.tokenManager.get("idToken");
};

export const setupRefresh = () => {
  authClient.tokenManager.on("expired", (key, expiredToken) => {
    authClient.tokenManager.get("accessToken");
    authClient.tokenManager.get("idToken");
  });
};

export const useOkta = onLogin => {
  useEffect(() => {
    setupRefresh();
    handleLogin(onLogin);
  }, []);
};
