import React, { useContext } from "react";
import AuthContext from "../contexts/AuthContext";
import { useOkta, handleLogin, setupRefresh } from "../hooks/useOkta";
import { getCurrentUser } from "../api/UserMgmtApi";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import RouteConstants from "../constants/RouteConstants";

const OktaLogin = withRouter(({ history, children }) => {
  const authContext = useContext(AuthContext);
  const { dispatch: authDispatch } = authContext;

  const getUserInfo = () => {
    //if userinfo is present in localstorage dont call the backend
    let userInfo = localStorage.getItem("userInfo");
    if (!userInfo) {
      getCurrentUser()
        .then(({ data }) => {
          authDispatch({ type: "setUserInfo", userInfo: data });
          history.push(RouteConstants.BASE);
        })
        .catch(error => {
          console.log(error);
          authDispatch({ type: "setUserInfoFailed", userInfoFailed: true });
          history.push(RouteConstants.LOGIN);
        });
    }
  };

  const onLogin = () => {
    authDispatch({ type: "login" });
    getUserInfo();
  };
  //If there is a # in the url which means it is new token.
  if (window.location.hash) {
    localStorage.clear();
  }
  if (!authContext.state.loggedIn) {
    useOkta(onLogin);
  } else if (authContext.state.loggedIn) {
    setupRefresh();
  }

  return <>{children}</>;
});

OktaLogin.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
};

export default OktaLogin;
